export default defineNuxtPlugin(() => {
  const appConfig = useAppConfig();

  // @ts-expect-error
  return {
    provide: {
      ui: appConfig.ui.presets,
    },
  };
});
